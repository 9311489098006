<script>
import ruleFetch from '../../../../mixins/ruleFetch.js'
    
export default {
  mixins: [ruleFetch],
  computed: {
    premiumTooltipLabel () {
      return 'PREMIUM FEATURE'
    },
    isReviewManager () {
      return this.company.tags && this.company.tags.includes('review_manager')
    }
  },
  methods: {
    isPremium () {
      return hasRule('premium_profiles')
    }
  }
}
</script>
